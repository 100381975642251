"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoricalSubscription = void 0;
var HistoricalSubscription = /** @class */ (function () {
    function HistoricalSubscription() {
        this.currentMetal = [];
        this.gold = [];
        this.silver = [];
        this.platinum = [];
        this.palladium = [];
        this.canadians = [];
        this.euros = [];
    }
    HistoricalSubscription.prototype.subscribeCurrent = function (callback) {
        this.currentMetal.push(callback);
    };
    HistoricalSubscription.prototype.subscribeGold = function (callback) {
        this.gold.push(callback);
    };
    HistoricalSubscription.prototype.subscribeSilver = function (callback) {
        this.silver.push(callback);
    };
    HistoricalSubscription.prototype.subscribePlatinum = function (callback) {
        this.platinum.push(callback);
    };
    HistoricalSubscription.prototype.subscribePalladium = function (callback) {
        this.palladium.push(callback);
    };
    HistoricalSubscription.prototype.subscribeCanadian = function (callback) {
        this.canadians.push(callback);
    };
    HistoricalSubscription.prototype.subscribeEuro = function (callback) {
        this.euros.push(callback);
    };
    HistoricalSubscription.prototype.unsubscribeCurrent = function (callback) {
        this.currentMetal = this.currentMetal.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.unsubscribeGold = function (callback) {
        this.gold = this.gold.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.unsubscribeSilver = function (callback) {
        this.silver = this.silver.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.unsubscribePlatinum = function (callback) {
        this.platinum = this.platinum.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.unsubscribePalladium = function (callback) {
        this.palladium = this.palladium.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.unsubscribeCanadian = function (callback) {
        this.canadians = this.canadians.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.unsubscribeEuro = function (callback) {
        this.euros = this.euros.filter(function (cb) { return cb !== callback; });
    };
    HistoricalSubscription.prototype.event = function (data, current) {
        if (current === void 0) { current = false; }
        if (current) {
            this.currentMetal.forEach(function (cb) { return cb(data); });
            return;
        }
        switch (data.currency) {
            case "Gold":
                this.gold.forEach(function (cb) { return cb(data); });
                break;
            case "Silver":
                this.silver.forEach(function (cb) { return cb(data); });
                break;
            case "Platinum":
                this.platinum.forEach(function (cb) { return cb(data); });
                break;
            case "Palladium":
                this.palladium.forEach(function (cb) { return cb(data); });
                break;
            case "Canadian":
                this.canadians.forEach(function (cb) { return cb(data); });
                break;
            case "Euro":
                this.euros.forEach(function (cb) { return cb(data); });
                break;
        }
    };
    return HistoricalSubscription;
}());
exports.HistoricalSubscription = HistoricalSubscription;
