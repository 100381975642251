"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupAlert = void 0;
// Check if query params 'alert' is present and show alert, then remove it from the URL
var setupAlert = function () {
    var urlParams = new URLSearchParams(window.location.search);
    var _alert = urlParams.get("alert");
    if (_alert) {
        alert(_alert);
        history.replaceState(null, "", window.location.pathname);
    }
};
exports.setupAlert = setupAlert;
